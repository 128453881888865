import axios from "axios";



const getToken = () => {
    const tokenString = localStorage.getItem('token');
    const userToken = JSON.parse(tokenString);
    return userToken?.token
};
axios.defaults.headers.common = {
    "key": getToken(),
  };
const apiUrl = "https://builder.kcbuilder.net/api/"

export function auth(pass) {
    try {
      return axios.get('https://builder.kcbuilder.net/authenticate', { auth: { username: 'admin', password: pass } })
    } catch (e) {
    }
};
export function addCat(cat) {
    return axios.post("https://builder.kcbuilder.net/api/categories", {
        category: cat.category
    })
}

export function deleteCat(id) {
    return axios.delete("https://builder.kcbuilder.net/api/categories/" + id )
}

export function getCategories() {
    //return axios.get(apiUrl + "categories")
    return axios.get(apiUrl + "categories")
}
