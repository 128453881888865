import React, { useState } from 'react';

async function loginUser(credentials) {
 return fetch('https://builder.kcbuilder.net/login', {
   method: 'POST',
   headers: {
     'Content-Type': 'application/json'
   },
   auth: {
    credentials
   },
   body: JSON.stringify(credentials)
 })
   .then(data => data.json())
}

export default function Login({ setToken }) {
  const [password, setPassword] = useState();

  const handleSubmit = async e => {
    e.preventDefault();
    const token = await loginUser({
      password
    });
    setToken(token);
  }

  return(
    <div className='wrapper bg-gradient-to-t from-gray-900 to-teal-900 min-h-screen text-xl text-gray-100 flex flex-col py-10'>
      <div className='p-10 container mx-auto rounded-t-xl flex gap-5 justify-center items-center border-b border-teal-900 border-dashed md:flex-row md:justify-between lg:max-w-4xl '>
        
        <form onSubmit={handleSubmit} className='border-2 border-teal-500 py-2 px-5 bg-teal-500/10 text-teal-500'>
            <h1 className='font-semibold min-w-full text-teal-500 flex  tracking-wider gap-2 min-w-full flow-root'>Please Log In</h1>

            <input type="password" placeholder="password" onChange={e => setPassword(e.target.value)} className='bg-transparent outline-none border-b-2 border-gray-500 py-2 px-5 text-center md:text-left' />
  
            <div>
            <button type="submit" className='border-2 border-teal-500 ml-1 py-2 px-5 bg-teal-500/10 text-teal-500 hover:bg-teal-500 duration-300 hover:text-gray-900 '>Submit</button>
            </div>
        </form>
      </div>
    </div>
  )
}

