import { useRef, useState, useEffect } from "react";
import { addCat, getCategories, deleteCat, auth } from "../services/catServices"
import { FiTrash } from "react-icons/fi";

const CatPost = () => {
    const [cat, setCat] = useState("")
    const [categories, setCategories] = useState([]);
    const [loggedIn, setLoggedIn] = useState(false)
    const inputRef = useRef(null);
    const [pass, setPass] = useState("")
    useEffect(() => {
        // getting data from the server
        fetchingData();
      }, []);
    
      // fetching data
      const fetchingData = async () => {
        try {

          const catRes = await getCategories();
          const catData = await catRes.data;
          catData.sort((a,b) => {return a.category>b.category ? 1:-1})
          setCategories(catData)
        } catch (error) {
          console.log(error.message);
        }
      };
    const catPostHandler=(e)=>{
        e.preventDefault();
        let newCategory = {
            category: cat
        }
        setCat(newCategory)
        catPosting(newCategory)
        inputRef.current.blur();
    }
    const catDeleteHandler = (id) => {
        deleteCat(id)
        setCategories(categories.filter((category) => id !== category._id))
    }
    const catPosting = async (cat) => {
        const res = await addCat(cat);
        const data = await res.data;
        setCategories([...categories, data])
        setCat("")
    }
    const handlePass = async(e) => {
        e.preventDefault()
        let answer = await auth(pass)
        if (answer.data === "OK"){
            setLoggedIn(true)
        }
    }
    return(
        <div className="min-w-full grid p-10 bg-gray-900 container mx-auto rounded-b-lg border-t border-teal-900 border-dashed lg:max-w-4xl">
        {loggedIn ? 
            (<div>
                <form
                className='bg-gray-900 p-10 min-w-full container mx-auto flex flex-col gap-5 justify-center items-center md:flex-row md:justify-between lg:max-w-4xl'
                    onSubmit={catPostHandler}>
                    <label>Category</label>
                    <input
                    ref={inputRef}
                    value={cat}
                    onChange={(e) => setCat(e.target.value)}
                    required
                    type='text'
                    placeholder='Category?'
                    className='bg-transparent outline-none border-b-2 border-gray-500 py-2 px-5 text-center md:text-left focus:border-teal-400 duration-300'
                />
                    <button
                    className='border-2 border-teal-500 py-2 px-5 bg-teal-500/10 text-teal-500 hover:bg-teal-500 duration-300 hover:text-gray-900 '
                    >Add</button>
                </form>
                <br/>
                <div
                    className='bg-transparent outline-none border-b-2 border-gray-500 py-2 px-5 text-center md:text-left focus:border-teal-400 duration-300'>
                    {categories
                    .map((category) => 
                    <p key={category._id} value={category.category} className='bg-gray-900 p-1 container mx-auto flex flex-col gap-5 justify-center items-center md:flex-row md:justify-between lg:max-w-4xl'>
                        {category.category}
                        <button onClick={() => {if(window.confirm('You are an admin and about to delete ' + category.category + ', click ok to confirm')){catDeleteHandler(category._id)}}}>
                        <FiTrash className='cursor-pointer hover:text-rose-500 duration-300' />
                        </button>
                    </p>)}
                </div>    
            </div>) : (
                <form onSubmit = {handlePass}>
                    <div className='border-2 border-teal-500 py-2 px-5 bg-teal-500/10 text-teal-500'>
                      <input 
                        value={pass}
                        onChange = {(e) => setPass(e.target.value)} 
                        ref={inputRef}
                        className='bg-transparent outline-none border-b-2 border-gray-500 py-2 px-5 text-center md:text-left'>
                      </input>
                      <button
                        type='submit'
                        className='border-2 border-teal-500 ml-1 py-2 px-5 bg-teal-500/10 text-teal-500 hover:bg-teal-500 duration-300 hover:text-gray-900 '
                        >
                          Submit
                      </button>
                    </div>  
                    
                    </form>)}
            
        </div>
    ) 
}
export default CatPost