import Login from "./components/Login";
import useToken from "./services/useToken";
import CatPost from "./components/CatPost";
import { BrowserRouter, Route, Routes } from 'react-router-dom';

const App = () => {
  const { token, setToken } = useToken();
  if(!token) {
    return <Login setToken={setToken} />
  }
  
  return (
    <div className='wrapper flex min-w-full bg-gradient-to-t from-gray-900 to-teal-900 min-h-screen text-xl text-gray-100 flex flex-col py-10'>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login/>}>
          </Route>
          <Route path="/tsc/catpost" element={<CatPost />}>
          </Route>
        </Routes>
      </BrowserRouter>  
      
      
    </div>
  );
};

export default App;
